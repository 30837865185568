import { Controller } from "@hotwired/stimulus"
import { Chart, ChartConfiguration } from "chart.js/auto"

export default class extends Controller<HTMLCanvasElement> {
  readonly colors = ['#f87171', '#fb923c', '#fbbf24', '#a3e635', '#4ade80', '#34d399', '#2dd4bf', '#22d3ee', '#60a5fa', '#a78bfa', '#e879f9', '#f472b6']
  declare readonly dataValue: any[]
  declare readonly titleValue: string
  declare readonly noAnimateValue: boolean
  declare chart: Chart
  static values = {
    data: Array,
    title: String,
    noAnimate: Boolean
  }

  
  declare config: ChartConfiguration
  connect() {
    const animationMap = new Map<Boolean, (false | undefined)>([
      [true, false],
      [false, undefined]
    ])
    
    if (this.config.options == null) this.config.options = {}
    this.config.options.responsive = true
    this.config.options.maintainAspectRatio = false
    this.config.options.animation = animationMap.get(this.noAnimateValue)
    Chart.defaults.font.size = 14;
    this.chart = new Chart(
      this.element,
      this.config
    )
  }

  disconnect() {
    this.chart.destroy()
    delete this.chart
    delete this.config
  }
}
