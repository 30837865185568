import ChartController from "./chart_controller"

export default class extends ChartController {
  initialize() {
    this.config = {
      type: 'bar',
      options: {
        scales: {
          y: {
            title: {
              text: 'Hours',
              display: true
            }
          }
        }
      },
      data: {
        labels: this.dataValue.map(row => String(row['Employee'])),
        datasets: [
          {
            label: 'Timesheeted',
            data: this.dataValue.map(row => row['Hours']),
            backgroundColor: this.colors[0]
          }
        ]
      }
    }
  }
}