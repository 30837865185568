import { Controller } from "@hotwired/stimulus"

function elm(html: string) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  return wrapper.firstChild;
}

function fileListItem(name: string, captionName: string) { 
  return elm(`<div class="flex mt-2" data-controller="direct" data-filevalidator-target="fileListItem">
    <div class="relative bg-gray-300 w-full" >
      <div class="top-0 flex justify-center items-center w-full h-full z-30">
        <input class="hidden" type="file" name="${name}" data-direct-target="fileInput">
        <span class="z-20 whitespace-nowrap pl-2 md:pl-4 overflow-hidden text-xs md:text-base w-full" data-direct-target="filename"><i>No File Selected</i></span>
        <input name="${captionName}" type="text" class="opacity-75 z-20 w-full" placeholder="Caption">
      </div>
      <div class="absolute z-10 top-0 bg-blue-300 h-full transition-all	" style="width:0%" data-direct-target="progress"></div>
    </div>
    <button class="z-20 btn btn-danger" type="button" data-action="direct#destroy">Delete</button>
  </div>`) 
}

function fileListItemWithFor(name: string, captionName: string, forName: string, forValue: string) { 
  return elm(`<div class="flex mt-2" data-controller="direct" data-filevalidator-target="fileListItem">
  <div class="relative bg-gray-300 w-full" >
    <div class="top-0 flex justify-center items-center w-full h-full z-30">
      <input class="hidden" type="file" name="${name}" data-direct-target="fileInput">
      <span class="z-20 whitespace-nowrap pl-2 md:pl-4 overflow-hidden text-xs md:text-base w-full" data-direct-target="filename"><i>No File Selected</i></span>
        <input name="${captionName}" type="text" class="opacity-75 z-20 w-full" placeholder="Caption">
      <input type="hidden" name="${forName}" value="${forValue}" >
    </div>
    <div class="absolute z-10 top-0 bg-blue-300 h-full transition-all	" style="width:0%" data-direct-target="progress"></div>
  </div>
  <button class="z-20 btn btn-danger" type="button" data-action="direct#destroy">Delete</button>
</div>`) 
}

export default class extends Controller<HTMLDivElement> {
  static targets = ['fileList']
  declare fileListTarget: HTMLDivElement
  declare form: HTMLFormElement
  connect() {
    this.form = this.element.closest('form')
    if (typeof this.form.dataset.attachmentIndex === 'undefined') {
      this.form.dataset.attachmentIndex = '0'
    }
  }

  chooseFile() {
    const index = parseInt(this.form.dataset.attachmentIndex)
    if (this.element.dataset.forName && this.element.dataset.forValue) {
      this.fileListTarget.appendChild(fileListItemWithFor(
        replaceImplicitIndexWithExplicit(this.element.dataset.name, index),
        replaceImplicitIndexWithExplicit(this.element.dataset.captionName, index),
        replaceImplicitIndexWithExplicit(this.element.dataset.forName, index),
        this.element.dataset.forValue
      ))
    } else {
      this.fileListTarget.appendChild(fileListItem(
        replaceImplicitIndexWithExplicit(this.element.dataset.name, index), 
        replaceImplicitIndexWithExplicit(this.element.dataset.captionName, index)
      ))
    }
    this.form.dataset.attachmentIndex = String(index + 1);
  }
}

// Hacky fix due to some browsers reordering form fields with the same name when submitting.
// 
// E.g:
// form_submission[attachments][][file] = <blob for attachment A>
// form_submission[attachments][][caption] = <caption for attachment A>
// form_submission[attachments][][file] = <blob for attachment B>
// form_submission[attachments][][caption] = <caption for attachment B>
// 
// gets reordered to:
// form_submission[attachments][][file] = <blob for attachment A>
// form_submission[attachments][][file] = <blob for attachment B>
// form_submission[attachments][][caption] = <caption for attachment A>
// form_submission[attachments][][caption] = <caption for attachment B>
//
// Rack::Utils.parse_nested_query requires that the feilds for the same object be next to each other like in the first example
// But, This doesn't matter if we explicitly set the index like:
// form_submission[attachments][0][file] = <blob for attachment A>
// form_submission[attachments][1][file] = <blob for attachment B>
// form_submission[attachments][0][caption] = <caption for attachment A>
// form_submission[attachments][1][caption] = <caption for attachment B>
//
// So, as a workaround we replace the implicit index [] with an explicit index [0]
function replaceImplicitIndexWithExplicit(name: String, index: Number) {
  return name.replace("[]", `[${index}]`)
}

